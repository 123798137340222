import React from "react"
import { Link } from "gatsby"

export default function Button(props) {
    if (props.path) {
        if (props.external) {
            return (
                <a
                    href={`${props.path}`}
                    className={props.className}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span>{props.label}</span>
                </a>
            )
        } else {
            return (
                <Link to={`${props.path}`} className={props.className}>
                    <span>{props.label}</span>
                </Link>
            )
        }
    }

    // onClickが渡された場合、ボタンとして機能させる
    return (
        <button className={props.className} onClick={props.onClick}>
            <span>{props.label}</span>
        </button>
    )
}

// 予備用古いコード（もっとみるが作動しない）

// import React from "react"
// import { Link } from "gatsby"

// export default function Button(props) {
//     if(props.path){
//         if(props.external){
//             return (
//                 <a href={`${props.path}`} className={props.className} target="_blank" rel="noopener noreferrer"><span>{props.label}</span></a>
//             )
//         } else {
//             return (
//                 <Link to={`${props.path}`} className={props.className}><span>{props.label}</span></Link>
//             )
//         }
//     }
//     return (
//         <span className={props.className}><span>{props.label}</span></span>
//     )
// }
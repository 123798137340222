import React from "react"
import Layout from "src/component/layout/layout"
import Button from "src/component/common/button"
import { Seo } from "src/component/common/seo"
import { graphql,useStaticQuery } from "gatsby"
import { useLocation } from "@reach/router"

export default function Detail({ data }) {
    // console.log(data.microcmsNews.content)
    console.log("デバッグ: ", data);
    return (
        <>
            <Layout>
                <article className="p-detail">
                    <div className="p-detail__inner c-inner-primary">
                        <div className="p-detail__content l-oldPost">
                            <div className="p-detail__date news-date">
                                <p>
                                {data.microcmsResources.display_date.split("T")[0].replaceAll("-", ".")}
                                </p>
                            </div>
                            
                            <div className="p-detail__title c-title01">
                                <h1 className="p-detail__title__text c-title01__text">{data.microcmsResources.title}</h1>

                                {(data.microcmsResources.type_category || data.microcmsResources.uses_category || data.microcmsResources["ai-services_category"]) && (
                                <ul className="p-detail__tag">
                                    {data.microcmsResources.type_category &&
                                    data.microcmsResources.type_category.map((category) => (
                                        <li key={category.id}>{category.name}</li>
                                    ))}
                                    {data.microcmsResources.uses_category &&
                                    data.microcmsResources.uses_category.map((category) => (
                                        <li key={category.id}>{category.name}</li>
                                    ))}
                                    {data.microcmsResources["ai-services_category"] &&
                                    data.microcmsResources["ai-services_category"].map((category) => (
                                        <li key={category.id}>{category.name}</li>
                                    ))}
                                </ul>
                                )}
                            </div>

                            {data.microcmsResources.resources_thumbnail && (
                                <div className="p-detail__thumbnail">
                                    <img 
                                        src={data.microcmsResources.resources_thumbnail.url} 
                                        alt={data.microcmsResources.title} 
                                    />
                                </div>
                            )}

                            <div className="p-detail__content__inner l-oldPost__inner">
                                
                                <div className="article-body">
                                    {data.microcmsResources.contents ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: data.microcmsResources.contents, // ここでcontentではなくcontentsを使用
                                        }}
                                        />
                                ) : null}
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                     <div className="p-detail__button">
                        <Button onClick={() => window.history.back()} className="c-button01" label="一覧に戻る" />
                     </div>
                </article>
            </Layout>
        </>
    )
}


export const query = graphql`
    query ($resourcesId: String!) {
        microcmsResources(resourcesId: { eq: $resourcesId }) {
            resourcesId
            display_date
            title
            post_category {
                id
                name
            }
            type_category {
                id
                name
            }
            uses_category {
                id
                name
            }
            resources_thumbnail {
                url
            }
            contents
        }
        site {
            siteMetadata {
                productionSiteUrl
            }
        }
        microcmsSetting {
            site_title
        }
    }
`;

export function Head({ data }) {
    const location = useLocation();
    const resources = data.microcmsResources;
    const siteMetadata = data.site.siteMetadata;
    const setting = data.microcmsSetting;

    const thumbnail = resources.resources_thumbnail;
    const eyecatch = thumbnail ? thumbnail.url : siteMetadata.productionSiteUrl + "img/common/microdata_logo.png";

    const schema = {
        "@context": "http://schema.org",
        "@type": "BlogPosting",
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": siteMetadata.productionSiteUrl + location.pathname,
        },
        "headline": resources.title,
        "datePublished": resources.display_date,
        "dateModified": resources.display_date,
        "publisher": {
            "@type": "Organization",
            "name": setting.site_title,
            "url": siteMetadata.productionSiteUrl,
            "logo": {
                "@type": "ImageObject",
                "url": siteMetadata.productionSiteUrl + "img/common/microdata_logo.png"
            },
            "address": {
                "@type": "PostalAddress",
                "postalCode": "150-0043",
                "addressRegion": "東京都",
                "addressLocality": "渋谷区",
                "streetAddress": "道玄坂一丁目19番9号 第一暁ビル6階"
            }
        },
        "image": {
            "@type": "ImageObject",
            "url": eyecatch
        },
        "mainEntity": {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
                {
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                        "@id": siteMetadata.productionSiteUrl,
                        "name": "TOP"
                    }
                },
                {
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                        "@id": siteMetadata.productionSiteUrl + "search",
                        "name": "記事一覧"
                    }
                },
                {
                    "@type": "ListItem",
                    "position": 3,
                    "item": {
                        "@id": siteMetadata.productionSiteUrl.slice(0, -1) + location.pathname,
                        "name": resources.title
                    }
                }
            ]
        }
    };

    const schemaAsString = JSON.stringify(schema, null, 2);

    return (
        <Seo
            title={resources.title}
            description=""
            pathname=""
        >
            <script type="application/ld+json">{schemaAsString}</script>
        </Seo>
    )
}
